import dayjs, { Dayjs } from 'dayjs'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSize } from 'react-hook-size'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import {
  AppointmentSearchResult,
  LocationType,
  NotificationPlacement,
  PublicNode,
  SupportedLanguage,
} from '../../../__generated__/api'
import AppointmentList from '../../../common/components/AppointmentList/AppointmentList'
import PractitionerList from '../../../common/components/AppointmentList/PractitionerList'
import AppointmentSearchSuggestions from '../../../common/components/AppointmentSearchSuggestions/AppointmentSearchSuggestions'
import BookingInstructions from '../../../common/components/BookingInstructions/BookingInstructions'
import CallbackInstructions from '../../../common/components/CallbackInstructions/CallbackInstructions'
import FilterOptions from '../../../common/components/FilterOptions/FilterOptions'
import { MinMaxDiv } from '../../../common/components/Layout/Layout'
import OHCWarningModal from '../../../common/components/OHCModal/OHCWarningModal'
import { PractitionerInsuranceUnsupported } from '../../../common/components/PractitionerDetails/PractitionerInsurance'
import PractitionerRestrictions from '../../../common/components/PractitionerRestrictions/PractitionerRestrictions'
import { useApi } from '../../../common/hooks/useApi'
import useMobileSearchHeight from '../../../common/hooks/useMobileSearchHeight'
import { useModals } from '../../../common/hooks/useModals'
import { useNode, useNodes } from '../../../common/hooks/useNode'
import { useOHC } from '../../../common/hooks/useOHC'
import useSearchRedirect from '../../../common/hooks/useSearchRedirect'
import api from '../../../common/services/api'
import * as Analytics from '../../../common/utils/analytics'
import { maxWidth } from '../../../common/utils/breakpoint'
import { generalErrorMessageAtom, isFromAppAtom } from '../../../state/common/atoms'
import {
  AppointmentSearchMode,
  appointmentSearchModeAtom,
  controlSearchNodeModal,
  isUserSelectedDateAtom,
  searchNodeModal,
  searchServiceModalAutoOpenAtom,
  selectedAppointmentListVariantAtom,
  selectedAppointmentTypesAtom,
  selectedDateAtom,
  selectedDurationsAtom,
  selectedFlexibleScheduleOptionAtom,
  selectedGenderAtom,
  selectedLanguageAtom,
  selectedNodeLocationAtom,
  selectedPatientAgeGroupAtom,
  selectedPractitionerLocationAtom,
  selectedSearchPractitionerAtom,
  selectedStandardScheduleOptionAtom,
  selectedTimeRangesAtom,
} from '../../../state/search/atoms'
import { getSelectedNodeOrDefault } from '../../../state/search/selectors'
import AppointmentNoteModal, {
  AppointmentNoteConfig,
  AppointmentNoteType,
  getAppointmentNoteConfig,
} from '../components/AppointmentNoteModal'
import DesktopDateSelect from '../components/DesktopDateSelect'
import NotificationBox from '../components/NotificationBox'
import ParentNodeChildSelect from '../components/ParentNodeChildSelect'
import SearchBar from '../components/SearchBar'
import SearchFilterModal from '../components/SearchFilterModal'
import SearchFlexiblePractitionerServiceSelect from '../components/SearchFlexiblePractitionerServiceSelect'
import SearchHeader from '../components/SearchHeader'
import {
  AppointmentListContainer,
  BottomContainer,
  Container,
  NAV_HEIGHT,
  NotificationsContainer,
  ResultsContainer,
} from '../components/SearchViewContainers'
import { SearchCalendarProvider } from '../hooks/useSearchCalendar'
import useSearchHistory from '../hooks/useSearchHistory'
import useSearchResults from '../hooks/useSearchResults'
import useSearchTarget, { SearchTargetValue } from '../hooks/useSearchTarget'

import { GetDCNotification } from './SearchView.utils'

export type SelectedAppointmentDetails = {
  appointment: AppointmentSearchResult
} & (
  | {
      node: PublicNode
    }
  | {
      practitionerId: number
      serviceId?: number
      duration?: number
      specialtyId?: number
      nodeId?: string
    }
)

type FlexibleAppointmentPreselection = {
  appointment: AppointmentSearchResult
  serviceId: number
  duration: number
}

interface Props {
  onAppointmentSelect(selection: SelectedAppointmentDetails): void
  onPractitionerDetailsClick(practitionerId: number): void
}

const controlForcesNode = (control: ['force', string] | 'skip' | null, node: string) =>
  Array.isArray(control) && control[0] === 'force' && control[1] === node

const SearchView: React.FC<React.PropsWithChildren<Props>> = ({
  onAppointmentSelect,
  onPractitionerDetailsClick,
}) => {
  useSearchHistory()

  const { t, i18n } = useTranslation()

  const defaultNrOfAppointmentsToShow = 60
  const {
    appointments,
    suggestions,
    pending: appointmentsPending,
    error: appointmentsError,
  } = useSearchResults()
  const { modals, getModal } = useModals()
  const { searchTarget, setSearchTarget } = useSearchTarget()
  const { isOHCSide, ohcAllowedStatus, namedCheckupOfficeCityId } = useOHC()
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [appointments])

  const { data: patientAgeGroups } = useApi(
    api.v1.getAgeGroups,
    { lang: i18n.language as SupportedLanguage },
    []
  )
  const { data: languages } = useApi(
    api.v1.getLanguages,
    { lang: i18n.language as SupportedLanguage },
    []
  )
  const isOHCAllowed = ohcAllowedStatus === 'allowed'

  const setGeneralErrorMessage = useSetRecoilState(generalErrorMessageAtom)

  const [appointmentListVariant, setAppointmentListVariant] = useRecoilState(
    selectedAppointmentListVariantAtom
  )
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDateAtom)
  const [selectedLocation, setSelectedLocation] = useRecoilState(selectedNodeLocationAtom)
  const [selectedPractitionerLocation, setSelectedPractitionerLocation] = useRecoilState(
    selectedPractitionerLocationAtom
  )
  const [selectedNodeId, setSelectedSearchNodeId] = useRecoilState(getSelectedNodeOrDefault)
  const selectedTimeRanges = useRecoilValue(selectedTimeRangesAtom)
  const selectedAppointmentTypes = useRecoilValue(selectedAppointmentTypesAtom)
  const selectedLanguage = useRecoilValue(selectedLanguageAtom)
  const selectedGender = useRecoilValue(selectedGenderAtom)
  const selectedPatientAgeGroup = useRecoilValue(selectedPatientAgeGroupAtom)
  const selectedDurations = useRecoilValue(selectedDurationsAtom)
  const selectedFlexibleScheduleOption = useRecoilValue(selectedFlexibleScheduleOptionAtom)
  const selectedStandardScheduleOption = useRecoilValue(selectedStandardScheduleOptionAtom)
  const isFromApp = useRecoilValue(isFromAppAtom)
  const selectedPractitioner = useRecoilValue(selectedSearchPractitionerAtom)
  const [nodeModal, setNodeModal] = useRecoilState(searchNodeModal)
  const [controlNodeModal, setControlNodeModal] = useRecoilState(controlSearchNodeModal)
  const setServiceModalAutoOpen = useSetRecoilState(searchServiceModalAutoOpenAtom)

  const [flexibleAppointmentPreselection, setFlexibleAppointmentPreselection] =
    useState<FlexibleAppointmentPreselection>()
  const [allAppointmentsVisible, setAllAppointmentsVisible] = useState(true)
  const isUserSelectedDate = useRecoilValue(isUserSelectedDateAtom)
  const appointmentSearchMode = useRecoilValue(appointmentSearchModeAtom)

  const [nodeModalShownForNode, setNodeModalShownForNode] = useState<string | null>(null)
  const [appointmentNote, setAppointmentNote] = useState<AppointmentNoteConfig | null>(null)

  const { data: locations, pending: locationsPending } = useApi(
    isOHCSide && isOHCAllowed ? api.v1.getOhcLocations : api.v1.getLocations,
    {
      lang: i18n.language as SupportedLanguage,
      ...(isOHCSide && isOHCAllowed ? { nodeId: selectedNodeId } : ({} as { nodeId: string })),
    },
    [],
    isOHCSide && isOHCAllowed ? Boolean(selectedNodeId) : true
  )

  const onDcRedirect = useCallback(
    (queue: string) => {
      if (isFromApp) {
        // TODO: AV3-1186
        window.location.replace(t('component.digitalClinicTile.redirectUrlOMApp'))
      } else {
        window.open(
          `${t(
            'component.digitalClinicTile.redirectUrlOMWeb'
          )}/?redirectAfterLogin=/digital-clinic/new/${queue}`
        )
      }
    },
    [isFromApp, t]
  )

  useEffect(() => {
    if (isOHCSide && isOHCAllowed && !locationsPending && locations.length > 0) {
      const ohcLocationsHasSelectedLocation = locations.some((location) =>
        selectedLocation.includes(location.uniqueId)
      )
      const ohcLocationsHasNamedCheckupOfficeCity = locations.some(
        (location) =>
          location.type === LocationType.City &&
          location.uniqueId === `${LocationType.City}${namedCheckupOfficeCityId}`
      )

      if (!ohcLocationsHasSelectedLocation && ohcLocationsHasNamedCheckupOfficeCity) {
        setSelectedLocation([`${LocationType.City}${namedCheckupOfficeCityId}`])
      }
    }
  }, [
    isOHCSide,
    isOHCAllowed,
    locationsPending,
    locations,
    selectedLocation,
    namedCheckupOfficeCityId,
    setSelectedLocation,
  ])

  const { flattenedNodes } = useNodes()
  const { node: selectedNode, pending: nodePending } = useNode(selectedNodeId)
  useEffect(() => {
    if (
      selectedNode?.id &&
      (!nodeModalShownForNode ||
        nodeModalShownForNode !== selectedNode.id ||
        controlForcesNode(controlNodeModal, selectedNode.id))
    ) {
      if (nodeModal && !selectedNode?.modalId) {
        setNodeModal(null)
        setNodeModalShownForNode(null)
        setControlNodeModal(null)
      }

      if (
        selectedNode?.modalId &&
        (!nodeModal || (nodeModal && nodeModalShownForNode !== selectedNode.id))
      ) {
        const modal = getModal(selectedNode.modalId)
        if (modal) {
          setNodeModal(modal)
          setServiceModalAutoOpen(false)
        }
      }

      if (
        nodeModal &&
        selectedNode?.modalId &&
        nodeModal.id === selectedNode.modalId &&
        !appointmentNote &&
        (nodeModalShownForNode !== selectedNode.id ||
          controlForcesNode(controlNodeModal, selectedNode.id))
      ) {
        if (controlNodeModal !== 'skip') {
          setAppointmentNote({
            type: AppointmentNoteType.CUSTOM,
            modal: nodeModal,
            onContinue: () => setAppointmentNote(null),
            onClose: () => {
              setAppointmentNote(null)
            },
            onDigitalClinic: onDcRedirect,
          })
        }
        setNodeModalShownForNode(selectedNode.id)
        setControlNodeModal(null)
      }
    }
  }, [
    appointmentNote,
    getModal,
    nodeModal,
    nodeModalShownForNode,
    onDcRedirect,
    selectedNode?.id,
    selectedNode?.modalId,
    setNodeModal,
    controlNodeModal,
    setControlNodeModal,
    setServiceModalAutoOpen,
  ])

  const { data: notifications } = useApi(
    api.v1.getNotifications,
    {
      lang: i18n.language,
      nodeId: selectedNodeId,
      locations: selectedLocation,
      isOHC: isOHCSide ? isOHCAllowed : false,
    },
    [],
    searchTarget.value === SearchTargetValue.Node
  )

  const { data: digitalClinicNotifications } = useApi(
    api.v1.getDkNotifications,
    {
      lang: i18n.language,
      nodeId: selectedNodeId,
      locations: selectedLocation,
      isOHC: isOHCSide ? isOHCAllowed : false,
    },
    [],
    searchTarget.value === SearchTargetValue.Node
  )

  const { data: durations } = useApi(
    api.v1.getNodeFlexibleSearchOptions,
    { locations: selectedLocation, nodeId: selectedNodeId },
    [],
    searchTarget.value === SearchTargetValue.Node
  )

  const { data: bookingInstructions } = useApi(
    api.v1.getBookingInstructions,
    {
      lang: i18n.language,
      nodeId: selectedNodeId,
      serviceId: selectedFlexibleScheduleOption?.serviceId,
    },
    null,
    Boolean(selectedNodeId || selectedFlexibleScheduleOption?.serviceId)
  )

  const onSuggestionSelect = (id: string, type: 'location' | 'node' | 'date', date?: Dayjs) => {
    if (date) {
      setSelectedDate(date)
    }

    switch (type) {
      case 'date':
        setSelectedDate(dayjs(id))
        break
      case 'location':
        const foundLocation = locations.find(
          (location) =>
            location.type === LocationType.Clinic &&
            (location.clinicIDs.includes(id) || location.assisDentBranchIDs.includes(id))
        )
        if (foundLocation) {
          if (searchTarget.value === SearchTargetValue.Practitioner) {
            setSelectedPractitionerLocation([foundLocation.uniqueId])
          } else {
            setSelectedLocation([foundLocation.uniqueId])
          }
        }
        break
      case 'node':
        const foundNode = flattenedNodes.find((node) => node.id === id)
        if (foundNode) {
          setSelectedSearchNodeId(foundNode.id)
        }
    }
  }

  const selectedLocationResult = locations.find((location) =>
    selectedLocation.includes(location.uniqueId)
  )
  const dcNotification = GetDCNotification(
    digitalClinicNotifications,
    selectedAppointmentTypes,
    appointments.length
  )

  const isDental = useMemo(
    () => selectedNode?.connections?.some((connection) => connection.type === 'assisdent') ?? false,
    [selectedNode?.connections]
  )

  Analytics.useTrackSearch({
    appointments,
    selectedDate,
    selectedNode,
    selectedTimeRanges,
    selectedAppointmentTypes,
    selectedLanguage,
    selectedGender,
    selectedPatientAgeGroup,
    selectedLocationResult,
    selectedDurations,
    pending: appointmentsPending && nodePending,
    isOHC: isOHCSide,
    isDental,
  })

  const ref = useRef<HTMLDivElement>(null)
  const { height: searchHeight } = useSize(ref)
  useMobileSearchHeight(isFromApp, ref.current?.clientHeight)

  useSearchRedirect(selectedNode)

  const locationName = useMemo(() => {
    if (searchTarget.value === SearchTargetValue.Practitioner) {
      if (selectedPractitionerLocation.length === 0) {
        return undefined
      }

      const found = locations.find((location) =>
        selectedPractitionerLocation.includes(location.uniqueId)
      )

      return found?.name
    }

    if (selectedLocationResult?.type === LocationType.All) {
      return t('component.searchView.remoteAppointments')
    }

    return selectedLocationResult?.name
  }, [t, searchTarget, selectedPractitionerLocation, locations, selectedLocationResult])

  const selectedNodeIsParent =
    !selectedNode?.parentId &&
    !selectedNode?.parentName &&
    selectedNode?.children &&
    selectedNode?.children.length > 0

  const topOfSearchNotifications = notifications.filter((notification) =>
    notification.placements.includes(NotificationPlacement.TopOfSearch)
  )

  const appointmentListNotifications =
    searchTarget.value === SearchTargetValue.Node
      ? dcNotification
        ? [dcNotification, ...notifications]
        : notifications
      : []

  if (selectedNode && selectedNodeIsParent) {
    return <ParentNodeChildSelect selectedParentNode={selectedNode} />
  }

  const supportedNode = selectedNode?.locationTypes.length
    ? selectedNode?.locationTypes.some(
        (locationType) => selectedLocationResult?.locationTypes.includes(locationType)
      ) ?? false
    : true

  const remoteSupported = (selectedNode?.video || selectedNode?.phone) ?? true

  if (isOHCSide && ohcAllowedStatus && ohcAllowedStatus !== 'allowed') {
    return <OHCWarningModal ohcWarningReason={ohcAllowedStatus} />
  }

  return (
    <Container data-mobile-header="main">
      <SearchHeader
        ref={ref}
        selectedNode={selectedNode}
        locations={locations}
        appointmentListVariant={appointmentListVariant}
        setAppointmentListVariant={setAppointmentListVariant}
      />
      <BottomContainer>
        <MinMaxDiv size={`${maxWidth.content}px`}>
          <ResultsContainer>
            <DesktopDateSelect />
            <AppointmentListContainer
              as="section"
              aria-labelledby="appointmentListContainerHeading"
            >
              <PractitionerInsuranceUnsupported practitionerDetails={selectedPractitioner} />
              {appointmentSearchMode !== AppointmentSearchMode.CALLBACK && (
                <PractitionerRestrictions practitionerDetails={selectedPractitioner} />
              )}
              {(topOfSearchNotifications.length > 0 ||
                bookingInstructions ||
                appointmentSearchMode === AppointmentSearchMode.CALLBACK) && (
                <NotificationsContainer>
                  {bookingInstructions && (
                    <BookingInstructions instructions={bookingInstructions} />
                  )}
                  {appointmentSearchMode === AppointmentSearchMode.CALLBACK && (
                    <CallbackInstructions />
                  )}
                  {topOfSearchNotifications.map((notification) => (
                    <NotificationBox
                      key={`notification-${notification.id}`}
                      notification={notification}
                      replaceLocation={selectedLocation}
                    />
                  ))}
                </NotificationsContainer>
              )}
              {(appointmentListVariant === 'time' ||
                searchTarget.value === SearchTargetValue.Practitioner) && (
                <>
                  <AppointmentList
                    appointments={appointments.length > 0 ? appointments : []}
                    appointmentsPending={appointmentsPending}
                    appointmentsError={appointmentsError}
                    notifications={appointmentListNotifications}
                    toShow={defaultNrOfAppointmentsToShow}
                    onAppointmentClick={(appointment) => {
                      if (searchTarget.value === SearchTargetValue.Node) {
                        if (!selectedNode) {
                          return
                        }

                        const appointmentNoteConfig = getAppointmentNoteConfig(
                          appointment,
                          selectedNode,
                          isOHCSide,
                          modals
                        )
                        if (appointmentNoteConfig) {
                          setAppointmentNote({
                            ...appointmentNoteConfig,
                            onContinue: () =>
                              onAppointmentSelect({ appointment, node: selectedNode }),
                            onClose: () => setAppointmentNote(null),
                            onDigitalClinic: onDcRedirect,
                          })
                          return
                        }

                        onAppointmentSelect({
                          appointment,
                          node: selectedNode,
                        })
                        return
                      }

                      if (searchTarget.value === SearchTargetValue.Practitioner) {
                        if (!selectedPractitioner) {
                          return
                        }

                        if (selectedPractitioner.hasFlexibleSchedule) {
                          if (!selectedFlexibleScheduleOption) {
                            return
                          }

                          if (selectedFlexibleScheduleOption?.availableDurations.length === 1) {
                            onAppointmentSelect({
                              appointment,
                              practitionerId: searchTarget.id,
                              duration: selectedFlexibleScheduleOption.availableDurations[0],
                              serviceId: selectedFlexibleScheduleOption.serviceId,
                              specialtyId: undefined,
                            })
                            return
                          }

                          setFlexibleAppointmentPreselection({
                            appointment,
                            serviceId: selectedFlexibleScheduleOption.serviceId,
                            duration: appointment.duration,
                          })
                        } else {
                          let nodeId
                          if (typeof selectedStandardScheduleOption !== 'number') {
                            if (selectedStandardScheduleOption.startsWith('node-')) {
                              nodeId = selectedStandardScheduleOption.substring('node-'.length)
                            }

                            if (selectedStandardScheduleOption.startsWith('reason-')) {
                              const reasonId = selectedStandardScheduleOption.substring(
                                'reason-'.length
                              )
                              nodeId = selectedPractitioner?.assisDentReasons?.find(
                                (reason) => reasonId === reason.id
                              )?.nodeId
                            }
                          }

                          onAppointmentSelect({
                            appointment,
                            practitionerId: searchTarget.id,
                            ...(selectedStandardScheduleOption === 'default'
                              ? {}
                              : typeof selectedStandardScheduleOption === 'number'
                              ? { duration: selectedStandardScheduleOption }
                              : {
                                  duration: appointment.duration,
                                  ...(nodeId
                                    ? {
                                        nodeId,
                                      }
                                    : {}),
                                }),
                          })
                        }
                      }
                    }}
                    onSpecialistClick={(appointmentDetails) =>
                      onPractitionerDetailsClick(appointmentDetails.specialistId)
                    }
                    onDcNotificationClick={onDcRedirect}
                    onNrOfAppointmentsVisibleChanged={(count: number) => {
                      setAllAppointmentsVisible(count === appointments.length)
                    }}
                    selectedDate={selectedDate}
                    supportedNode={supportedNode}
                    isUserSelectedDate={isUserSelectedDate}
                    remoteSupported={remoteSupported}
                  />
                  {suggestions && allAppointmentsVisible && (
                    <AppointmentSearchSuggestions
                      suggestions={suggestions}
                      onSelect={onSuggestionSelect}
                    />
                  )}
                </>
              )}
              {appointmentListVariant === 'specialist' &&
                searchTarget.value !== SearchTargetValue.Practitioner && (
                  <PractitionerList
                    appointments={appointments}
                    appointmentsPending={appointmentsPending}
                    onPractitionerSelect={(practitionerId) =>
                      setSearchTarget(
                        { value: SearchTargetValue.Practitioner, id: practitionerId },
                        searchTarget.id as string
                      )
                    }
                    onSpecialistClick={(appointmentDetails) =>
                      onPractitionerDetailsClick(appointmentDetails.specialistId)
                    }
                    isUserSelectedDate={isUserSelectedDate}
                    remoteSupported={remoteSupported}
                  />
                )}
            </AppointmentListContainer>
          </ResultsContainer>
        </MinMaxDiv>
      </BottomContainer>
      <SearchFilterModal>
        <FilterOptions
          languages={languages}
          patientAgeGroups={patientAgeGroups}
          durations={durations}
          minimal={searchTarget.value === SearchTargetValue.Practitioner}
          selectedNode={selectedNode}
        />
      </SearchFilterModal>
      {!isFromApp && (
        <SearchBar
          offset={searchHeight ?? NAV_HEIGHT}
          name={selectedPractitioner?.name ?? selectedNode?.name ?? ''}
          locationName={locationName}
        />
      )}
      {flexibleAppointmentPreselection?.appointment.appointmentId && (
        <SearchFlexiblePractitionerServiceSelect
          appointmentId={flexibleAppointmentPreselection.appointment.appointmentId}
          preselectedServiceId={flexibleAppointmentPreselection.serviceId}
          preselectedDuration={flexibleAppointmentPreselection.duration}
          appointmentNotAvailableCallback={() => {
            setGeneralErrorMessage('error.appointmentNotFound')
            setFlexibleAppointmentPreselection(undefined)
          }}
          onClose={() => setFlexibleAppointmentPreselection(undefined)}
          onSelect={(duration) =>
            onAppointmentSelect({
              appointment: flexibleAppointmentPreselection.appointment,
              practitionerId: flexibleAppointmentPreselection.appointment.specialistId,
              serviceId: flexibleAppointmentPreselection.serviceId,
              duration,
            })
          }
        />
      )}
      <AppointmentNoteModal note={appointmentNote} />
    </Container>
  )
}

const SearchViewWrapper: React.FC<React.PropsWithChildren<Props>> = (props) => {
  return (
    <SearchCalendarProvider>
      <SearchView {...props} />
    </SearchCalendarProvider>
  )
}

export default SearchViewWrapper
